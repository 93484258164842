import _ from 'lodash'
import React, { useState } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'

import SideBar from 'components/Navigation/SideBar'

import AddAccounts from 'pages/Sources/AddAccounts'
import AddSource from 'pages/Sources/AddSource'
import RedirectSource from 'pages/Sources/Redirect'
import Sources from 'pages/Sources/Sources'

import Competitors from 'pages/Competitors/Competitors'
import CompetitorsReport from 'pages/Competitors/CompetitorsReport'

import PageSpeed from 'pages/PageSpeed/PageSpeed'

import ClustersOverview from 'pages/Clusters/Campaigns/ClustersOverview'
import Clusters from 'pages/Clusters/Clusters'
import ClustersChooseTab from 'pages/Clusters/ClustersChooseTab'

import Dashboard from 'pages/Dashboard/Dashboard'

import Page404, { Error404 } from 'pages/ErrorPages/404'

import ManualExpensesList from 'pages/ManualExpenses/ManualExpensesList'
import ManualExpenseView from 'pages/ManualExpenses/ManualExpenseView'

import BillingCheckout from 'pages/Billing/Checkout'
import BillingDetails from 'pages/Billing/Details'
import BillingPortal from 'pages/Billing/Portal'

import DataLoad from 'pages/DataLoad/DataLoad'

import useGetSubscription from 'containers/billing/useGetSubscription'
import useListEmbedReports from 'containers/embed/useListEmbedReports'
import OrganizationsContext from 'containers/organizations/organizationsContext'
import useGetOrganizations from 'containers/organizations/useGetOrganizations'
import UserContext from 'containers/user/userContext'
import useUser from 'containers/user/useUser'
import useStorage from 'containers/useStorage'

import slugify from 'utils/slugify'

import usePageTitle from 'containers/pageTitle/usePageTitle'
import produce from 'immer'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import * as rudderanalytics from 'rudder-sdk-js'

import { FeatureProvider } from 'containers/features/FeatureContext'
import Products from 'pages/Products/Products'
import GooglePlayBucket from './Sources/GooglePlayBucket'
import { SidebarProvider } from 'containers/SidebarContext'
import { ReportsProvider } from 'containers/reports/ReportsContext'
import Topbar from 'components/Navigation/Topbar'
import Settings from 'pages/Settings/Settings'
import AppleAds from './Sources/AppleAds'
import CustomTable from 'components/CustomTable'
import useGetFeatures from 'containers/features/useGetFeatures'
import GA4 from './Sources/GA4'
import useGetEmbedStatus from 'containers/embed/useGetEmbedStatus'
import Demo from './Demo'
import useSubscriptions from 'containers/useSubscriptions'
import LinkSubscriptions from 'pages/Subscriptions/LinkSubscriptions'
import { SubscriptionProvider } from 'containers/subscriptions/SubscriptionContext'
import NotificationPopup from './Notifications/NotificationPopup'
import NotificationSummary from 'pages/Notifications/NotificationSummary'
import { NotificationProvider } from 'containers/notifications/NotificationContext'
import useDestinations from "containers/destinations/useDestinations"
import useQuery from 'containers/useQuery'
import useRedirect from 'containers/useRedirect'
import Moosend from './Sources/Moosend'


function getWholeDays(date) {
  return Math.floor(date / (1000 * 60 * 60 * 24))
}

function dateDiff(startDate, endDate) {
  return getWholeDays(endDate) - getWholeDays(startDate)
}

const OrganizationRoute = () => {
  const { organizationSlug } = useParams()
  console.log('organization route rerender', organizationSlug)
  let { path } = useRouteMatch()
  const storage = useStorage()
  const query = useQuery()

  // redirect to the specified page with params
  useRedirect(organizationSlug, query)

  localStorage.setItem('latest-organization', organizationSlug)
  const lsOrgId = localStorage.getItem(`organization-${organizationSlug}-id`)
  const lsOrgIsDemo = localStorage.getItem(
    `organization-${organizationSlug}-is-demo`
  )
  const lsOrgName = localStorage.getItem(
    `organization-${organizationSlug}-name`
  )

  const [organizationsInfo, setOrganizationsInfo] = useState({
    currentOrganization: {
      slug: organizationSlug,
      id: lsOrgId ? parseInt(lsOrgId) : lsOrgId,
      is_demo: lsOrgIsDemo === null ? null : lsOrgIsDemo === 'true',
      name: lsOrgName,
      currency_id: 'USD',
    },
    isLoadingOrganization: true,
  })

  const { organizationsError, getOrganizations } = useGetOrganizations()
  const { subscription, getSubscription, loadingSubscription } =
    useGetSubscription()

  const {checkUserSubscriptions} = useSubscriptions()

  const [user, setUser] = useState()
  const { userError, getUser } = useUser()

  const [page, setPage] = useState('sources')
  const [embedReportSlug, setEmbedReportSlug] = useState(null)
  const [embedReports, fetchEmbedReports, embedReportsFetchStatus] =
    useListEmbedReports()
  const [embedViewMode, setEmbedViewMode] = useState('view')

  const [clusterBreadcrumb, setClusterBreadcrumb] = useState({
    category: null,
    name: null,
    url: null,
  })
  const [features, getFeatures] = useGetFeatures(
    organizationsInfo?.currentOrganization
  )
  const [, getEmbedStatus] = useGetEmbedStatus(
    organizationsInfo?.currentOrganization?.id
  )
  const {checkOrganizationExternalDb} = useDestinations(organizationsInfo?.currentOrganization?.id)
  const [isEmbedEnabled, setIsEmbedEnabled] = useState(false)
  const [isVerified, setIsVerified] = useState(false)

  const [unlinkedSubscriptions, setUnlinkedSubscriptions] = useState([])

  // State for Notification action -> MODAL
  const [showLatestNotificationPopup, setShowLatestNotificationPopup] = useState(true)
  const [showActionModal, setShowShowActionModal] = useState(false)

  // State for Notification action -> REDIRECT

  const history = useHistory()

  // Handle title
  const locationPath = useLocation().pathname
  const { changeTitle } = usePageTitle('Maya')

  useEffect(() => {
    (async () => {
      let tempUser 
      try {
        tempUser = await getUser()
        if (tempUser && tempUser.is_email_verified) {
          setIsVerified(true)
        }
      } catch (error) {
        if (error.response.data.detail.type === "email_not_verified") {
          history.push({
            pathname: '/verify-email',
            search: `?email=${error.response.data.detail.email}`
          })
        }
        return;
      } 

    })()
  }, [locationPath])

  useEffect(() => {
    (async () => {
      // check if user has unlinked subscriptions and redirect to link page
      const subscriptionInfo = await checkUserSubscriptions()
    
      if (subscriptionInfo?.has_unlinked_subscription && isVerified) {
        const subscriptions = subscriptionInfo.subscriptions;
        setUnlinkedSubscriptions(subscriptions) 
        return
      }
    })()
  }, [isVerified]) 

  useEffect(() => {
    changeTitle(locationPath)
  }, [locationPath, changeTitle])
  // End handle title

  const getData = async () => {
    const [tempOrganizations, tempUser] = await Promise.all([
      getOrganizations(),
      getUser(),
    ])
    setUser(tempUser)

    if (!tempUser) {
      return
    }

    const traits = {
      email: tempUser.email,
      createdAt: tempUser.date_joined,
      organizationsCount: tempUser.organizations_count,
    }

    if (tempUser.profile) {
      traits.name =
        tempUser.profile.first_name + ' ' + tempUser.profile.last_name
      traits.firstName = tempUser.profile.first_name
      traits.lastName = tempUser.profile.last_name
      traits.title = tempUser.profile.company_position
    }

    let gtmArgs = {
      dataLayer: {
        event: 'identify',
        user_params: {
          userId: tempUser.id,
          userEmail: tempUser.email,
          userFirstName: tempUser.profile ? tempUser.profile.first_name : null,
          userLastName: tempUser.profile ? tempUser.profile.last_name : null,
        }
      },
    }

    if (!tempOrganizations) {
      TagManager.dataLayer(gtmArgs)
      // TagManager.dataLayer({ dataLayer: { event: `user_org_loaded` } })
      // rudderanalytics.identify(tempUser.id, traits)
      // rudderanalytics.track('user_org_loaded')
      return
    }

    const currentOrganization = _.find(tempOrganizations, [
      'slug',
      organizationSlug,
    ])

    if (currentOrganization) {
      traits.company = {
        id: currentOrganization.id,
        name: currentOrganization.name,
      }
      storage.setCurrentOrganization({ organizationSlug })
      gtmArgs.dataLayer.user_params.organizationId = currentOrganization.id
      gtmArgs.dataLayer.user_params.organizationName = currentOrganization.name
      gtmArgs.dataLayer.user_params.organizationSlug = organizationSlug
    }

    setOrganizationsInfo({
      organizations: tempOrganizations,
      currentOrganization,
      isLoadingOrganization: false,
    })

    localStorage.setItem(
      `organization-${organizationSlug}-id`,
      currentOrganization.id
    )
    localStorage.setItem(
      `organization-${organizationSlug}-is-demo`,
      currentOrganization.is_demo
    )
    localStorage.setItem(
      `organization-${organizationSlug}-name`,
      currentOrganization.name
    )

    TagManager.dataLayer(gtmArgs)
    // rudderanalytics.identify(tempUser.id, traits)
    // rudderanalytics.track('user_org_loaded')
  }

  useEffect(() => {
    getData()
    // TODO: remove when all old external database users have converted
    checkOrganizationExternalDb()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (organizationsInfo.isLoadingOrganization) {
      return
    }
    storage.setCurrentOrganization({
      organizationSlug,
      organizations: organizationsInfo.organizations,
    })

    setOrganizationsInfo(
      produce(organizationsInfo, (draft) => {
        draft.currentOrganization = _.find(organizationsInfo.organizations, [
          'slug',
          organizationSlug,
        ])
      })
    )
    // eslint-disable-next-line
  }, [organizationSlug])

  useEffect(() => {
    if (organizationsInfo?.currentOrganization?.id) {
      getSubscription(organizationsInfo.currentOrganization.id)
      fetchEmbedReports(organizationsInfo.currentOrganization.id)
      getFeatures()
      ;(async () => {
        const embedStatus = await getEmbedStatus()
        setIsEmbedEnabled(embedStatus?.is_embed_enabled) 
      })()
    }
    // eslint-disable-next-line
  }, [organizationsInfo?.currentOrganization?.id])

  if (organizationsError && page !== 'redirect-source') {
    // in case of redirect-source the request is canceled due to redirect
    // and that page does not wait for orgainzation loading anyway
    return <>There was an issue loading your organizations.</>
  }

  if (userError) {
    return <>There was an issue loading your account information.</>
  }

  if (
    !organizationsInfo.isLoadingOrganization &&
    !organizationsInfo.currentOrganization
  ) {
    return <Page404 />
  }

  let subscriptionAlert = SubscriptionAlert()

  const renderPage = (nextPage, component, props = {}) => {
    props.organizationSlug = organizationSlug
    props.currentOrganization = organizationsInfo.currentOrganization
    if (page === 'clusters') {
      props.setClusterBreadcrumb = setClusterBreadcrumb
    }
    setTimeout(() => {
      setPage(nextPage)
      setEmbedReportSlug(null)
    }, 0)

    // rudderanalytics.page()
    return React.createElement(component, props)
  }

  const handleEditReport = (reportSlug) => {
    history.push(
      `/organization/${organizationSlug}/dashboard/${reportSlug}/edit`
    )
  }

  const onSaved = async (e) => {
    if (e.detail.saveAs) {
      const reportSlug = slugify(e.detail.reportName)
      history.push(`/organization/${organizationSlug}/dashboard/${reportSlug}`)
    }
    setTimeout(
      () => fetchEmbedReports(organizationsInfo.currentOrganization.id),
      500
    )
  }

  const renderEmbedReport = (props = {}) => {
    setPage('dashboard')
    const reportSlug = props.match?.params?.reportSlug || '-seo-v1'
    setEmbedReportSlug(reportSlug)
    const viewMode = props.viewMode || 'view'
    setEmbedViewMode(viewMode)
    // add key prop to dashboard in order to force re-render when reports change
    // this fixes the issue with reports not being updated after being loaded with mobile layout
    return (
      <Dashboard
        key={reportSlug}
        onSaved={onSaved}
        reports={embedReports}
        {...props}
      />
    )
  }

  const renderDemoReport = (props = {}) => {
    setPage('demo')
    const reportSlug = props.match?.params?.reportSlug || 'Spend'
    return (
      <Demo reportSlug={reportSlug}></Demo>
    )
  }

  const handleClickLatestNotificationPopup = () => {
    //TODO: this should be replaced by the action
    setShowShowActionModal(true)
    setShowLatestNotificationPopup(false);
  }
  
  const handleCloseLinkSubscriptions = () => {
    //TODO: this should be replaced by the action
    setShowShowActionModal(false)
    setShowLatestNotificationPopup(true)
  }

  return (  isVerified ? 
    <NotificationProvider currentOrganization={organizationsInfo?.currentOrganization}>
      <SidebarProvider isEmbedEnabled={isEmbedEnabled}>
        <ReportsProvider>
          <Topbar
            organizations={organizationsInfo.organizations}
            isLoadingOrganization={organizationsInfo.isLoadingOrganization}
            organizationSlug={organizationSlug}
            currentOrganization={organizationsInfo.currentOrganization}
            user={user}
            page={page}
            clusterBreadcrumb={clusterBreadcrumb}
          />
          <div className="page-row">
            {page !== 'billing' &&
              page !== 'billingCheckout' &&
              page !== 'redirect-source' && (
                <SideBar
                  organizationSlug={organizationSlug}
                  currentOrganization={organizationsInfo.currentOrganization}
                  page={page}
                  user={user}
                  embedReportSlug={embedReportSlug}
                  embedViewMode={embedViewMode}
                  subscription={subscription}
                  loadingSubscription={loadingSubscription}
                  embedReports={embedReports}
                  embedReportsFetchStatus={embedReportsFetchStatus}
                  fetchEmbedReports={fetchEmbedReports}
                  handleEditReport={handleEditReport}
                />
              )}
            <div className="side-page">
              {page === 'dashboard' || page === 'demo'  &&
                !organizationsInfo.currentOrganization.is_etl_enabled && (
                  <div className="alert alert-warning" role="alert">
                    Your free trial of Maya has ended. To continue upgrade your
                    pricing plan{' '}
                    <a href={`/organization/${organizationSlug}/billing/products`}>
                      here
                    </a>
                  </div>
                )}
                {/* TODO: Remove for now */}
              {/* {page !== 'billing' &&
                page !== 'billingCheckout' &&
                page !== 'dashboard' &&
                page !== 'billingDetails' &&
                subscriptionAlert} */}
              <UserContext.Provider value={user}>
                <OrganizationsContext.Provider value={organizationsInfo}>
                  <SubscriptionProvider value={unlinkedSubscriptions}>
                    <FeatureProvider value={features}>
                      <Switch>
                        <Route
                          exact
                          path={`${path}/sources`}
                          render={() => renderPage('sources', Sources)}
                        />
                        <Route
                          exact
                          path={`${path}/sources/add`}
                          render={() => renderPage('add-source', AddSource)}
                        />
                        <Route
                          exact
                          path={`${path}/sources/:serviceUuid/redirect`}
                          render={() =>
                            renderPage('redirect-source', RedirectSource)
                          }
                        />
                        <Route
                          exact
                          path={`${path}/sources/setup/custom-table/:id`}
                          render={() => renderPage('custom-table', CustomTable)}
                        />
                        <Route
                          exact
                          path={`${path}/sources/setup/custom-table/:id/edit`}
                          render={() =>
                            renderPage('custom-table', CustomTable, { edit: true })
                          }
                        />
                        <Route
                          exact
                          path={`${path}/sources/setup/google_play/:id`}
                          render={() => renderPage('google-play', GooglePlayBucket)}
                        />
                        <Route
                          exact
                          path={`${path}/sources/setup/moosend/:id`}
                          render={() => renderPage('moosend', Moosend)}
                        />
                        <Route exact path={`${path}/sources/setup/apple_ads/:uuid`} render={() => renderPage('apple-ads', AppleAds)}/>
                        <Route
                          exact
                          path={`${path}/sources/setup/google_analytics_4_events/:id`}
                          render={() =>
                            renderPage('google_analytics_4_events', GA4)
                          }
                        />
                        <Route
                          exact
                          path={`${path}/competitors`}
                          render={() => renderPage('competitors', Competitors)}
                        />
                        <Route
                          exact
                          path={`${path}/competitors/:competitorsId`}
                          render={() =>
                            renderPage('competitors', CompetitorsReport)
                          }
                        />
                        <Route
                          exact
                          path={`${path}/pagespeed`}
                          render={() => renderPage('pagespeed', PageSpeed)}
                        />
                        <Route
                          exact
                          path={`${path}/account-settings`}
                          render={() =>
                            renderPage('account-settings', Settings, {
                              settingType: 'account',
                            })
                          }
                        />
                        <Route
                          exact
                          path={`${path}/account-settings/:tab`}
                          render={() =>
                            renderPage('account-settings', Settings, {
                              settingType: 'account',
                            })
                          }
                        />
                        <Route
                          exact
                          path={`${path}/settings`}
                          render={() =>
                            renderPage('settings', Settings, {
                              settingType: 'administration',
                            })
                          }
                        />
                        <Route
                          exact
                          path={`${path}/settings/:tab`}
                          render={() =>
                            renderPage('settings', Settings, {
                              settingType: 'administration',
                            })
                          }
                        />
                        <Route
                          exact
                          path={`${path}/settings/:tab/:categorySlug`}
                          render={() =>
                            renderPage('settings', Settings, {
                              settingType: 'administration',
                            })
                          }
                        />
                        <Route
                          exact
                          path={`${path}/clusters/:clusterCategory/:columnId`}
                          render={() => renderPage('clusters', ClustersChooseTab)}
                        />
                        <Route
                          exact
                          path={`${path}/clusters/:clusterCategory`}
                          render={() => renderPage('clusters', ClustersOverview)}
                        />
                        <Route
                          exact
                          path={`${path}/clusters`}
                          render={() => renderPage('clusters', Clusters)}
                        />
                        <Route
                          exact
                          path={`${path}/dashboard/`}
                          render={() => renderEmbedReport({ viewMode: 'view' })}
                        />
                        <Route
                          exact
                          path={`${path}/dashboard/:reportSlug/edit`}
                          render={(props) =>
                            renderEmbedReport({ ...props, viewMode: 'edit' })
                          }
                        />
                        <Route
                          exact
                          path={`${path}/dashboard/:reportSlug`}
                          render={(props) => renderEmbedReport(props)}
                        />
                        <Route
                          exact
                          path={`${path}/demo/:reportSlug`}
                          render={(props) => renderDemoReport(props)}
                        />
                        <Route
                          exact
                          path={`${path}/integrations/add`}
                          render={() => renderPage('add-source', AddAccounts)}
                        />
                        <Route
                          exact
                          path={`${path}/billing/portal`}
                          render={() => renderPage('billing', BillingPortal)}
                        />
                        <Route
                          exact
                          path={`${path}/billing/checkout`}
                          render={() =>
                            renderPage('billingCheckout', BillingCheckout)
                          }
                        />
                        <Route
                          exact
                          path={`${path}/billing/details`}
                          render={() =>
                            renderPage('billingDetails', BillingDetails)
                          }
                        />
                        <Route
                          exact
                          path={`${path}/billing/products`}
                          render={() => renderPage('billingProducts', Products)}
                        />
                        <Route
                          exact
                          path={`${path}/expenses`}
                          render={() => renderPage('expenses', ManualExpensesList)}
                        />
                        <Route
                          exact
                          path={`${path}/expenses/:expenseId`}
                          render={() => renderPage('expenses', ManualExpenseView)}
                        />
                        <Route
                          exact
                          path={`${path}/dataload`}
                          render={() => renderPage('dataload', DataLoad)}
                        />
                        <Route
                          exact
                          path={`${path}/notifications`}
                          render={() => renderPage('notifications', NotificationSummary)}
                        />
                        <Route component={() => renderPage('error', Error404)} />
                      </Switch>
                    </FeatureProvider>
                  </SubscriptionProvider>
                </OrganizationsContext.Provider>
              </UserContext.Provider>
            </div>
          </div>
        </ReportsProvider>
        {/* This handles the displaying of the subscription popup */}
        {unlinkedSubscriptions?.length > 0 && <LinkSubscriptions subscriptions={unlinkedSubscriptions} onClose={handleCloseLinkSubscriptions} show={showActionModal}/>}
        <NotificationPopup onClick={handleClickLatestNotificationPopup} show={showLatestNotificationPopup} onTrigger={(trigger) => setShowLatestNotificationPopup(trigger)} currentOrganization={organizationsInfo.currentOrganization}/>
      </SidebarProvider>
    </NotificationProvider>
  : <></>)

  function SubscriptionAlert() {
    let subscriptionAlert = <></>
    if (subscription) {
      if (subscription.status === 'trialing' &&
        subscription.needs_payment_method) {
        const trialDays = dateDiff(
          new Date(),
          new Date(subscription.trial_ends_at)
        )
        if (trialDays === 0) {
          subscriptionAlert = (
            <div className="alert alert-info" role="alert">
              Your free trial expires soon.{' '}
              <a
                href={`/organization/${organizationSlug}/billing/portal`}
                target="_blank"
                rel="noreferrer"
              >
                Add a payment method
              </a>{' '}
              to avoid account deactivation when trial period ends.
            </div>
          )
        } else {
          /*
          subscriptionAlert = <div className="alert alert-info" role="alert">
            Your free trial has {trialDays} day{trialDays > 1 ? 's' : ''} remaining. <a href={`/organization/${organizationSlug}/billing/portal`} target="_blank" rel="noreferrer">Add a payment method</a> to avoid account deactivation when trial period ends.
          </div>
          */
        }
      } else if (subscription.status === 'past_due') {
        subscriptionAlert = (
          <div className="alert alert-warning" role="alert">
            We have been unable to collect payment for your last invoice. Please{' '}
            <a
              href={`/organization/${organizationSlug}/billing/portal`}
              target="_blank"
              rel="noreferrer"
            >
              update your payment details
            </a>{' '}
            or contact support to avoid account deactivation.
          </div>
        )
      } else if (subscription.status === 'canceled' ||
        subscription.status === 'unpaid') {
        subscriptionAlert = (
          <div className="alert alert-danger" role="alert">
            Your subscription has been cancelled. You are still able to make
            changes, but your data are not being updated. Please{' '}
            <a
              href={`/organization/${organizationSlug}/billing/portal`}
              target="_blank"
              rel="noreferrer"
            >
              renew your subscription
            </a>{' '}
            or contact support to reactivate your account.
          </div>
        )
      }
    } else if (!loadingSubscription) {
      subscriptionAlert = (
        <div className="alert alert-info" role="alert">
          You are on free trial. Please{' '}
          <a
            href={`/organization/${organizationSlug}/billing/details?checkout=1`}
            target="_blank"
            rel="noreferrer"
          >
            enter your payment details
          </a>{' '}
          to avoid account deactivation when trial period ends.
        </div>
      )
    }
    return subscriptionAlert
  }
}

export default OrganizationRoute 
