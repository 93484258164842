import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import produce from 'immer'
import TagManager from 'react-gtm-module'

import ScreenLoading from '../../components/ScreenLoading'
import { Redirect } from 'components/Redirect'

import './AddAccounts.scss'
import useGetAuthUrl from 'containers/services/useGetAuthUrl'
import useGetAccounts from 'containers/services/useGetAccounts'
import useConnectAccounts from 'containers/services/useConnectAccounts'
import useGetServices from 'containers/services/useGetServices'
import OrganizationsContext from 'containers/organizations/organizationsContext'

const Arrow = ({ open, handleClickArrow }) => {
  return (
    <span
      onClick={handleClickArrow}
      className="material-icons-outlined parent-arrow"
    >
      {open ? 'expand_more' : 'chevron_right'}
    </span>
  )
}

const CheckBox = ({ selected, handleSelect, enabled }) => {
  return (
    <span
      onClick={enabled ? handleSelect : () => {}}
      className={
        'material-icons-outlined check-box' +
        (!enabled ? ' check-box-disabled' : '')
      }
    >
      {selected === true ? 'check_box' : 'check_box_outline_blank'}
    </span>
  )
}

const Account = ({
  depth,
  account,
  accountsByParent,
  selectedAccounts,
  setSelectedAccounts,
  openAccounts,
  setOpenAccounts,
  isSearching,
  searchAccount,
  service,
}) => {
  const [isSelectable, setIsSelectable] = useState(true)

  const handleClickArrow = () => {
    if (accountsByParent[account.id_label]) {
      if (!openAccounts.has(account.id_label)) {
        setOpenAccounts(
          produce(openAccounts, (draftOpenAccounts) => {
            draftOpenAccounts.add(account.id_label)
          })
        )
      } else {
        setOpenAccounts(
          produce(openAccounts, (draftOpenAccounts) => {
            draftOpenAccounts.delete(account.id_label)
          })
        )
      }
    }
  }

  const handleSelect = () => {
    let tempSelectedAccounts = new Set(selectedAccounts)

    if (!selectedAccounts.has(account.identifier)) {
      tempSelectedAccounts.add(account.identifier)
    } else {
      tempSelectedAccounts.delete(account.identifier)
    }

    setSelectedAccounts(tempSelectedAccounts)
  }

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))

    return (
      <span>
        {' '}
        {parts.map((part, i) => (
          <span
            key={i}
            className={`${
              part.toLowerCase() === highlight.toLowerCase()
                ? 'highlighted'
                : ''
            }`}
          >
            {part}
          </span>
        ))}
      </span>
    )
  }

  useEffect(() => {
    const deselect = () => {
      let tempSelectedAccounts = new Set(selectedAccounts)

      if (tempSelectedAccounts.has(account.identifier)) {
        tempSelectedAccounts.delete(account.identifier)
        setSelectedAccounts(tempSelectedAccounts)
      }
    }

    if (service.id !== 'google_search_console') {
      setIsSelectable(true)
      return
    }
    if (account.name.endsWith('[site]')) {
      for (let selectedAccount of selectedAccounts) {
        if (selectedAccount.startsWith('sc-domain:')) {
          let domain = selectedAccount.substr(10)
          if (account.identifier.indexOf(domain) !== -1) {
            deselect()
            setIsSelectable(false)
            return
          }
        }
      }
    } else {
      let domain = account.identifier.substr(10)
      for (let selectedAccount of selectedAccounts) {
        if (!selectedAccount.startsWith('sc-domain:')) {
          if (selectedAccount.indexOf(domain) !== -1) {
            deselect()
            setIsSelectable(false)
            return
          }
        }
      }
    }
    setIsSelectable(true)
    return
  }, [service, account, selectedAccounts, setSelectedAccounts])

  return (
    <>
      <tr
        style={{
          fontSize: `${100 - depth * 5}%`,
          opacity: `${100 - depth * 10}%`,
        }}
      >
        <td style={{ paddingLeft: `${33 * depth}px` }} className="name-column">
          {accountsByParent[account.id_label] ? (
            <Arrow
              open={openAccounts.has(account.id_label)}
              handleClickArrow={handleClickArrow}
            />
          ) : (
            <CheckBox
              handleSelect={handleSelect}
              selected={selectedAccounts.has(account.identifier)}
              enabled={isSelectable}
            />
          )}
          {
            "metadata" in account && "thumbnail_url" in account?.metadata ? 
              <div class="thumbnail">
                <img alt src={account.metadata.thumbnail_url}/>
              </div> 
            : <></>
          }
          <div
            onClick={
              accountsByParent[account.id_label]
                ? handleClickArrow
                : handleSelect
            }
            className={!isSelectable ? 'check-box-label-disabled' : ''}
          >
            {isSearching
              ? getHighlightedText(account.name, searchAccount)
              : account.name}
          </div>
        </td>
        <td className="id-column">{account.id_label}</td>
      </tr>
      {accountsByParent[account.id_label] &&
        openAccounts.has(account.id_label) &&
        accountsByParent[account.id_label].map((childAccount) => {
          return (
            <Account
              key={childAccount.id_label}
              depth={depth + 1}
              account={childAccount}
              accountsByParent={accountsByParent}
              selectedAccounts={selectedAccounts}
              setSelectedAccounts={setSelectedAccounts}
              openAccounts={openAccounts}
              setOpenAccounts={setOpenAccounts}
              service={service}
            />
          )
        })}
    </>
  )
}

const AccountsTable = ({
  accountsByParent,
  selectedAccounts,
  setSelectedAccounts,
  openAccounts,
  setOpenAccounts,
  searchAccount,
  accounts,
  organization,
  service,
}) => {
  const { getAuthUrl } = useGetAuthUrl()

  const handleSwitchAccount = async () => {
    const url = await getAuthUrl(service.uuid, organization.id)
    if (url) {
      localStorage.removeItem('serviceAccounts')
      window.location.replace(url)
    }
  }

  if (
    !accountsByParent ||
    !accountsByParent[null] ||
    !accountsByParent[null].length
  ) {
    if (service.id === 'facebook_ads') {
      return (
        <div style={{ margin: 'auto', width: '800px' }}>
          <p>
            No associated Facebook Ads account found.
            <br />
          </p>
          <button
            className="submit-button"
            style={{ display: 'inline', fontWeight: 400 }}
            onClick={() => handleSwitchAccount()}
          >
            Connect another account
          </button>{' '}
          or add an ad account via{' '}
          <a
            href="https://business.facebook.com/settings/"
            target="_blank"
            rel="noreferrer"
          >
            Business Manager
            <span
              className="material-icons-outlined"
              style={{ fontSize: '0.8em', color: '#739570' }}
            >
              launch
            </span>
          </a>
          .
        </div>
      )
    } else {
      return (
        <div style={{ margin: 'auto', textAlign: 'center', width: '800px' }}>
          <p>
            No valid source account found.
            <br />
          </p>
          <button
            className="submit-button"
            style={{ display: 'inline', fontWeight: 400 }}
            onClick={() => handleSwitchAccount()}
          >
            Connect with another account
          </button>
        </div>
      )
    }
  }
  return (
    <table className="accounts-table">
      <thead>
        <tr>
          <th className="name-column">Account Name</th>
          <th className="id-column">Account ID</th>
        </tr>
      </thead>
      <tbody>
        {searchAccount === '' ? (
          <>
            {accountsByParent[null].map((account) => (
              <Account
                key={account.identifier}
                depth={0}
                account={account}
                accountsByParent={accountsByParent}
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
                openAccounts={openAccounts}
                setOpenAccounts={setOpenAccounts}
                isSearching={false}
                service={service}
              />
            ))}
          </>
        ) : (
          <>
            {accounts
              .filter(
                (account) =>
                  (account.name ? account.name : '')
                    .toLowerCase()
                    .includes(searchAccount.toLowerCase()) &&
                  !accountsByParent[account.id_label]
              )
              .map((account) => (
                <Account
                  key={account.identifier}
                  depth={0}
                  account={account}
                  accountsByParent={accountsByParent}
                  selectedAccounts={selectedAccounts}
                  setSelectedAccounts={setSelectedAccounts}
                  openAccounts={openAccounts}
                  setOpenAccounts={setOpenAccounts}
                  isSearching={true}
                  searchAccount={searchAccount}
                  service={service}
                />
              ))}
            {accounts.filter(
              (account) =>
                (account.name ? account.name : '')
                  .toLowerCase()
                  .includes(searchAccount.toLowerCase()) &&
                !accountsByParent[account.id_label]
            ).length === 0 && (
              <tr>
                <td className="no-accounts grid-column-12">
                  <span className="no-accounts-found">No accounts found</span>
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  )
}

export const AccountsBox = ({
  accountsByParent,
  service,
  selectedAccounts,
  setSelectedAccounts,
  openAccounts,
  setOpenAccounts,
  loadingAccounts,
  accounts,
  organization,
}) => {
  const [searchAccount /*, setSearchAccount*/] = useState('')

  /* 
  const handleChange = event => {
    setSearchAccount(event.target.value)
  }
  */

  return (
    <div className="accounts-box">
      <div className="accounts-box-head">
        <img alt={service.id} height="35" src={service.logo}></img>
        {/*<input type='text' placeholder='Search...' onChange={handleChange} value={searchAccount} className='search-bar'/>
        <span className="material-icons-outlined">
          search
        </span>*/}
      </div>
      {loadingAccounts ? (
        <ScreenLoading />
      ) : (
        <AccountsTable
          accountsByParent={accountsByParent}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
          openAccounts={openAccounts}
          setOpenAccounts={setOpenAccounts}
          searchAccount={searchAccount}
          accounts={accounts.accounts}
          organization={organization}
          service={service}
        />
      )}
    </div>
  )
}

const AddAccounts = () => {
  let history = useHistory()
  const { accounts, loadingAccounts, getAccounts, accountsError } =
    useGetAccounts()
  const {
    connectAccounts,
    loadingConnectAccounts,
    connectAccountsError,
    setConnectAccountsError,
  } = useConnectAccounts()
  const { services, servicesError, getServices, loadingServices } =
    useGetServices()

  const [selectedAccounts, setSelectedAccounts] = useState(new Set())
  const [openAccounts, setOpenAccounts] = useState(new Set())

  const { currentOrganization, isLoadingOrganization } =
    useContext(OrganizationsContext)

  useEffect(() => {
    getServices()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isLoadingOrganization) {
      getAccounts(currentOrganization.id)
    }
    // eslint-disable-next-line
  }, [currentOrganization, isLoadingOrganization])

  if (servicesError) {
    return <>{servicesError}</>
  }

  if (loadingServices) {
    return <ScreenLoading />
  }

  if (accountsError) {
    return (
      <Redirect
        to={{
          pathname: `/organization/${currentOrganization.slug}/sources/add`,
          state: {
            error: accountsError,
          },
        }}
      />
    )
  }

  const service = _.find(services, [
    'id',
    localStorage.getItem('redirectServiceId'),
  ])

  if (selectedAccounts === null) {
    return <ScreenLoading />
  }

  const handleClickBack = () => {
    localStorage.removeItem('serviceAccounts')
    history.push('/')
  }

  const getSelectedIdentifiers = () => {
    return Array.from(selectedAccounts)
  }

  const handleConnect = async () => {
    if (selectedAccounts.size > 0) {
      if (
        await connectAccounts(
          accounts.credentials_id,
          currentOrganization.id,
          getSelectedIdentifiers()
        )
      ) {
        TagManager.dataLayer({
          dataLayer: { event: 'connected_source_accounts' },
        })
        localStorage.removeItem('serviceAccounts')
        localStorage.setItem('serviceConnected', service.name)
        history.push(`/organization/${currentOrganization.slug}/sources`)
      }
    } else {
      setConnectAccountsError('You have to select at least one account')
    }
  }

  let accountsByParent = null

  if (accounts && accounts.single_account) {
    localStorage.removeItem('serviceAccounts')
    localStorage.setItem('serviceConnected', service.name)
    history.push(`/organization/${currentOrganization.slug}/sources`)
  }

  if (!loadingAccounts) {
    accountsByParent = _.groupBy(
      _.map(accounts.accounts, (a) => {
        return { ...a, parent_id: a.parent_id ?? null }
      }),
      'parent_id'
    )
    accountsByParent[null] = accountsByParent[null] || []
  }

  return (
    <div className="add-accounts">
      <span
        onClick={handleClickBack}
        className="material-icons back-arrow non-selectable"
      >
        arrow_back
      </span>
      <div className="head">
        <h2>Integration</h2>
        <span className="subtitle-text">Your selection</span>
      </div>
      <div className="content">
        <AccountsBox
          accountsByParent={accountsByParent}
          service={service}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
          openAccounts={openAccounts}
          setOpenAccounts={setOpenAccounts}
          loadingAccounts={loadingAccounts || isLoadingOrganization}
          accounts={accounts}
          organization={currentOrganization}
        />
        {!loadingAccounts && (
          <div className="actions">
            {connectAccountsError && (
              <span className="error">{connectAccountsError}</span>
            )}
            {loadingConnectAccounts ? (
              <ScreenLoading />
            ) : (
              <>
                {accounts &&
                  accountsByParent &&
                  accountsByParent[null].length > 0 && (
                    <button onClick={handleConnect} className="submit-button">
                      Connect
                    </button>
                  )}
                <button onClick={handleClickBack} className="link">
                  Cancel
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(AddAccounts)
